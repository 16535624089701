import {type ClassValue, clsx} from 'clsx'
import {twMerge} from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const __DEV__ = import.meta.env.DEV
export const __PROD__ = import.meta.env.PROD
export const __BRANCH_DEPLOYMENT__ =
  import.meta.env.VITE_TAG !== 'latest' && __PROD__
