import {Trans} from '@lingui/macro'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'

export const JournalTable = () => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <Trans>Number</Trans>
          </TableHead>
          <TableHead className="w-32">
            <Trans>Date</Trans>
          </TableHead>
          <TableHead className="w-96">
            <Trans>Title</Trans>
          </TableHead>
          <TableHead className="right-0 font-medium">
            <Trans>User</Trans>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array.from({length: 5}).map((_, index) => (
          <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell className="font-medium">
              {new Date().toLocaleDateString('de-DE')}
            </TableCell>
            <TableCell>
              <Trans>journalEntry.title</Trans>
            </TableCell>
            <TableCell className="font-medium">
              <Trans>journalEntry.user</Trans>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
