import {msg} from '@lingui/macro'
import {z} from 'zod'

export const NestSizeDto = z.enum([
  'NONE',
  'TINY',
  'SMALL',
  'MEDIUM',
  'LARGE',
  'XLARGE',
  'XXLARGE',
  'XXXLARGE',
])
export type NestSizeDto = z.infer<typeof NestSizeDto>

export const NEST_SIZE_MESSAGES = {
  [NestSizeDto.enum.NONE]: msg`None`,
  [NestSizeDto.enum.TINY]: msg`Tiny`,
  [NestSizeDto.enum.SMALL]: msg`Small`,
  [NestSizeDto.enum.MEDIUM]: msg`Medium`,
  [NestSizeDto.enum.LARGE]: msg`Large`,
  [NestSizeDto.enum.XLARGE]: msg`XLarge`,
  [NestSizeDto.enum.XXLARGE]: msg`XXLarge`,
  [NestSizeDto.enum.XXXLARGE]: msg`XXXLarge`,
}
