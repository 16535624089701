import {RouterProvider, createRouter} from '@tanstack/react-router'
import {StrictMode, Suspense} from 'react'
import {createRoot} from 'react-dom/client'

import './index.css'
import {queryClient} from './lib/api/queryClient.ts'
import {database} from './lib/db/index.ts'
import {useLanguageStore} from './lib/i18n/languageStore.ts'
import {routeTree} from './routeTree.gen.ts'

const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultViewTransition: true,
  context: {
    queryClient,
    database,
  },
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

useLanguageStore
  .getState()
  .setLanguage(useLanguageStore.getState().language)
  .then(() => {
    createRoot(document.getElementById('root')!).render(
      <Suspense fallback={null}>
        <StrictMode>
          <RouterProvider router={router} />
        </StrictMode>
      </Suspense>
    )
  })
