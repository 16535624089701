import {Control, geoJSON, Map as LeafletMap} from 'leaflet'
import {
  tileLayerOffline,
  savetiles,
  SaveStatus,
  getStorageInfo,
  getStoredTilesAsJson,
  TileLayerOffline,
} from 'leaflet.offline'
import {useEffect, useState} from 'react'

import downloadSvg from '../../../assets/download.svg?raw'
import trashSvg from '../../../assets/trash.svg?raw'

export const useOfflineMap = (map: LeafletMap | null, url: string) => {
  const [progress, setProgress] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)
  const [totalOfflineTiles, setTotalOfflineTiles] = useState(0)
  const [lodadedOfflineTiles, setLoadedOfflineTiles] = useState(0)

  // Setup offline map
  useEffect(() => {
    if (!map) {
      return
    }

    const baseLayer = tileLayerOffline(url, {
      minZoom: 13,
    }).addTo(map)

    const saveControl = savetiles(baseLayer, {
      zoomlevels: [13, 14, 15, 16], // optional zoomlevels to save, default current zoomlevel
      alwaysDownload: false,
      confirm: (layer: SaveStatus, successCallback: () => void) => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
          successCallback()
        }
      },
      confirmRemoval: (_layer: SaveStatus, successCallback: () => void) => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        if (window.confirm('Remove all the tiles?')) {
          successCallback()
        }
      },
      saveText: centerHTML(downloadSvg),
      rmText: centerHTML(trashSvg),
    })
    saveControl.addTo(map)

    baseLayer.on('savestart', e => {
      setTotalOfflineTiles((e as any as SaveStatus)._tilesforSave.length)
      setLoadedOfflineTiles(0)
      setIsDownloading(true)
    })

    baseLayer.on('loadtileend', () => setLoadedOfflineTiles(prev => prev + 1))

    const layerswitcher = new Control.Layers(
      {
        'osm (offline)': baseLayer,
      },
      undefined,
      {collapsed: false}
    ).addTo(map)

    void storageLayer(url, baseLayer, layerswitcher)

    return () => {
      map.removeLayer(baseLayer)
      saveControl.remove()
      baseLayer.off('savestart')
      baseLayer.off('loadtileend')
      baseLayer.off('saveend')
      map.removeControl(layerswitcher)
    }
  }, [map, url])

  // Compute progress
  useEffect(() => {
    if (totalOfflineTiles === 0) {
      setProgress(0)
      return
    }
    const prog = (lodadedOfflineTiles / totalOfflineTiles) * 100
    if (prog === 100) {
      setIsDownloading(false)
    }
    setProgress(prog)
  }, [lodadedOfflineTiles, totalOfflineTiles])

  return {progress, isDownloading}
}

const centerHTML = (html: string) =>
  // eslint-disable-next-line lingui/no-unlocalized-strings
  `<div class="flex justify-center items-center h-full">${html}</div>`

const storageLayer = async (
  url: string,
  baseLayer: TileLayerOffline,
  layerswitcher: Control.Layers
) => {
  const tiles = await getStorageInfo(url)
  const geojson = getStoredTilesAsJson(baseLayer.getTileSize(), tiles)
  const layer = geoJSON(geojson)
  // eslint-disable-next-line lingui/no-unlocalized-strings
  layerswitcher.addOverlay(layer, 'offline tiles')
}
