import ky from 'ky'

import {useAuthStore} from '../auth/authStore'

export const api = ky.create({
  prefixUrl: import.meta.env.VITE_API_URL,
  hooks: {
    beforeRequest: [
      request => {
        const token = useAuthStore.getState().token
        if (token) {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      },
    ],
  },
})
