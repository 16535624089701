import {FC} from 'react'

import {locales, Locale, useLanguageStore} from '@/lib/i18n/languageStore'

export const LanguageSwitcher: FC = () => {
  const {language, setLanguage, isLoaded} = useLanguageStore()

  const languages = Object.keys(locales) as Locale[]

  if (languages.length <= 1) {
    return null
  }

  return (
    <div className="flex gap-2 p-2 text-lg">
      {languages.map(locale => (
        <button
          key={locale}
          onClick={() => setLanguage(locale)}
          disabled={!isLoaded}
          data-testid={`language-${locale}`}
          className={locale === language ? 'font-bold' : ''}
        >
          {locales[locale]}
        </button>
      ))}
    </div>
  )
}
