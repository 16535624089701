import {useNavigate} from '@tanstack/react-router'
import {LogIn, LogOut} from 'lucide-react'
import {FC} from 'react'

import {useAuthStore} from '@/lib/auth/authStore.ts'

export const AuthenticationButton: FC = () => {
  const isAuthenticated: boolean = useAuthStore.getState().isAuthenticated()
  const logout = useAuthStore(state => state.logout)
  const navigate = useNavigate({from: '/'})
  const handleLogout = () => {
    logout()
    navigate({to: '/auth'}).then()
  }

  const handleLogin = () => {
    navigate({to: '/auth'}).then()
  }

  if (isAuthenticated) {
    return (
      <div className="flex gap-2 pl-6 text-lg">
        <button title="logout" onClick={handleLogout}>
          <LogOut />
        </button>
      </div>
    )
  } else {
    return (
      <div className="flex gap-2 pl-6 text-lg">
        <button title="login" onClick={handleLogin}>
          <LogIn />
        </button>
      </div>
    )
  }
}
