import {t} from '@lingui/macro'
import {QueryClient, MutationCache} from '@tanstack/react-query'
import {toast} from 'sonner'

import {nestService} from '../nest/nestService'
import {nestKeys} from '../nest/query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
    },
  },
  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    onSuccess: (data: any, variables, context, mutation) => {
      console.log('Success', {
        data,
        variables,
        context,
        mutation,
      })
    },
    onError: (error: any) => {
      console.error(error.message)
    },
  }),
})

queryClient.setMutationDefaults(nestKeys.all(), {
  mutationFn: async ({id, name}) => {
    await queryClient.cancelQueries({queryKey: nestKeys.detail(id)})
    return nestService.update(id, name)
  },
  onError: (error: any) => {
    toast.error(error.message, {
      cancel: {
        label: t`Dismiss`,
        onClick: () => toast.dismiss(),
      },
      duration: Infinity,
    })
    console.error(error.message)
  },
})
