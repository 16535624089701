import ky from 'ky'

import {HeightRequestParamsDto} from './dtos/HeightRequestParamsDto'
import {REFERENCE_SYSTEMS} from './dtos/ReferenceSystem'

// Docs: https://api3.geo.admin.ch/services/sdiservices.html
const geoApi = ky.create({
  prefixUrl: 'https://api3.geo.admin.ch/rest/services',
})

function getHeight({easting, northing, sr}: HeightRequestParamsDto) {
  return geoApi
    .get('height', {
      searchParams: {easting, northing, sr: REFERENCE_SYSTEMS[sr]},
    })
    .json<{height: number}>()
}

export const geoAdminService = {
  getHeight,
}
