import {onlineManager} from '@tanstack/react-query'
import {useEffect, useState} from 'react'

export const NETWORK_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
} as const

export type NetworkStatus = (typeof NETWORK_STATUS)[keyof typeof NETWORK_STATUS]

const getNetworkStatus = (isOnline: boolean) =>
  isOnline ? NETWORK_STATUS.ONLINE : NETWORK_STATUS.OFFLINE

export const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>(
    getNetworkStatus(navigator.onLine)
  )

  useEffect(() => {
    // create event handler
    const handleStatusChange = () => {
      setNetworkStatus(getNetworkStatus(navigator.onLine))
      onlineManager.setOnline(navigator.onLine)
    }

    // listen for online and ofline event
    window.addEventListener('online', handleStatusChange)
    window.addEventListener('offline', handleStatusChange)

    // clean up to avoid memory-leak
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [])

  return networkStatus
}
