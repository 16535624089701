import {z} from 'zod'

import {NestSizeDto} from './NestSizeDto'

export const NestDetailDto = z.object({
  id: z.string(),
  nestCode: z.string(),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  size: NestSizeDto,
  isFavorite: z.boolean(),
  species: z.string(),
  place: z.string(),
})
export type NestDetailDto = z.infer<typeof NestDetailDto>
