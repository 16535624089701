import {Trans} from '@lingui/macro'
import {createFileRoute, redirect} from '@tanstack/react-router'

import {useAuthStore} from '@/lib/auth/authStore'

export const Route = createFileRoute('/')({
  component: HomeComponent,
  loader: () => {
    const isAuthenticated = useAuthStore.getState().isAuthenticated()
    if (!isAuthenticated) {
      throw redirect({
        to: '/auth',
      })
    }
    throw redirect({
      to: '/nests/overview/all',
      search: {page: 0},
    })
  },
})

function HomeComponent() {
  return (
    <div className="p-2">
      <h3>
        <Trans>Welcome Home!</Trans>
      </h3>
    </div>
  )
}
