import {cn} from '@/lib/utils'

import {useNetworkStatus} from './useNetworkStatus'

export const NetworkStatus = () => {
  const networkStatus = useNetworkStatus()

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn('size-2 rounded', {
          'bg-green-500': networkStatus === 'online',
          'bg-red-500': networkStatus === 'offline',
        })}
      ></div>
    </div>
  )
}
