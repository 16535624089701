import {t} from '@lingui/macro'
import {synchronize} from '@nozbe/watermelondb/sync'
import {toast} from 'sonner'

import {syncService} from '@/lib/sync/syncService'

import {database} from './'

export async function sync() {
  await synchronize({
    database,
    pullChanges: async ({lastPulledAt, schemaVersion, migration}) => {
      const {timestamp, data: changes} = await syncService.pull({
        last_pulled_at: lastPulledAt,
        schema_version: schemaVersion,
        migration: encodeURIComponent(JSON.stringify(migration)),
      })
      return {changes, timestamp}
    },
    onDidPullChanges: async () => {
      console.log('Pulled changes')
      toast.success(t`Changes synchronised successfully`)
    },
    pushChanges: async ({changes}) => {
      console.log('Pushing changes', changes)
      await syncService.push(changes)
    },
    migrationsEnabledAtVersion: 1,
  })
}
