import {FC, useEffect} from 'react'
import {useMap} from 'react-leaflet'

type Props = {
  latitute: number
  longitude: number
}

export const RecenterAutomatically: FC<Props> = ({latitute, longitude}) => {
  const map = useMap()
  useEffect(() => {
    requestAnimationFrame(() => {
      map.setView([latitute, longitude], map.getZoom(), {
        animate: false,
      })
    })
  }, [latitute, longitude, map])
  return null
}
