import ky from 'ky'

import {LoginDto} from './dtos/LoginDto'
import {UserDto} from './dtos/UserDto'
import {ApiResponseDto} from '../api/dtos/ApiResponseDto'

const api = ky.create({
  prefixUrl: import.meta.env.VITE_API_URL,
})

export function login(json: LoginDto) {
  return api.post('api/user/login', {json}).json<ApiResponseDto<UserDto>>()
}

export const authService = {
  login,
}
