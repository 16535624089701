import {Trans, t} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {ChevronLeft, LoaderCircle, LocateFixed} from 'lucide-react'
import {FC, useState} from 'react'
import {UseFormReturn, useWatch} from 'react-hook-form'
import {Marker, Popup} from 'react-leaflet'

import {Button} from '@/components/ui/button'
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {NestCreateDto} from '@/lib/nest/dtos/NestCreateDto'
import {NEST_SIZE_MESSAGES, NestSizeDto} from '@/lib/nest/dtos/NestSizeDto'

import {SwissCoordinatesAlert} from './SwissCoordinatesAlert'
import {NestMap} from '../NestMap'
import {bluePinIcon} from '../NestMap/icons/BluePinIcon'
import {RecenterAutomatically} from '../NestMap/RecenterAutomatically'

type Props = {
  form: UseFormReturn<NestCreateDto>
  onSubmit: (nest: NestCreateDto) => void
}

export const NestForm: FC<Props> = ({form, onSubmit}) => {
  const [isLocationLoading, setIsLocationLoading] = useState(false)
  const setCurrentLocation = () => {
    if (!('geolocation' in navigator) || isLocationLoading) {
      return
    }

    setIsLocationLoading(true)
    navigator.geolocation.getCurrentPosition(position => {
      console.log(position)
      form.setValue('latitude', position.coords.latitude)
      form.setValue('longitude', position.coords.longitude)
      setIsLocationLoading(false)
    })
  }
  const latitude = useWatch({control: form.control, name: 'latitude'})
  const longitude = useWatch({control: form.control, name: 'longitude'})

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <Link to="/nests/overview" search={{page: 0}}>
          <Button variant="outline" size="icon" className="h-7 w-7">
            <ChevronLeft className="w-4 h-4" />
            <span className="sr-only">
              <Trans>Back</Trans>
            </span>
          </Button>
        </Link>

        <div className="text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0">
          <Trans>Create new Nest</Trans>
        </div>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <Card>
            <CardHeader>
              <CardTitle>
                <Trans>Nest Location</Trans>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-4">
                <>
                  {/* TOOD: Pass in nests */}
                  <NestMap
                    nests={[]}
                    latitude={latitude}
                    longitude={longitude}
                    onMove={([latitude, longitude]) => {
                      form.setValue('latitude', latitude)
                      form.setValue('longitude', longitude)
                    }}
                  >
                    {latitude && longitude && (
                      <>
                        <Marker
                          icon={bluePinIcon}
                          position={[latitude, longitude]}
                        >
                          <Popup>
                            <Trans>New nest location</Trans>
                          </Popup>
                        </Marker>
                        <RecenterAutomatically
                          latitute={latitude}
                          longitude={longitude}
                        />
                      </>
                    )}
                  </NestMap>
                </>
                <div className="flex items-end flex-grow gap-4">
                  <FormField
                    control={form.control}
                    name="latitude"
                    render={({field}) => (
                      <FormItem className="flex-grow">
                        <FormLabel>
                          <Trans>Latitude</Trans>
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            step="0.0001"
                            onChange={e =>
                              field.onChange(e.target.valueAsNumber)
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="longitude"
                    render={({field}) => (
                      <FormItem className="flex-grow">
                        <FormLabel>
                          <Trans>Longitude</Trans>
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            step="0.0001"
                            onChange={e =>
                              field.onChange(e.target.valueAsNumber)
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button
                    type="button"
                    onClick={() => setCurrentLocation()}
                    disabled={isLocationLoading}
                  >
                    {isLocationLoading ? (
                      <LoaderCircle className="animate-spin" />
                    ) : (
                      <LocateFixed />
                    )}
                  </Button>
                </div>
                {latitude && longitude && (
                  <>
                    <SwissCoordinatesAlert
                      latitude={latitude}
                      longitude={longitude}
                    />
                  </>
                )}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>
                <Trans>Nest Form</Trans>
              </CardTitle>
            </CardHeader>

            <CardContent>
              <FormField
                control={form.control}
                name="nestCode"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Nest Code</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="species"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Species</Trans>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        onChange={e => field.onChange(e.target.value)}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="size"
                render={({field}) => (
                  <FormItem>
                    <FormLabel>
                      <Trans>Nest size</Trans>
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t`Select nest size`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(NestSizeDto.enum).map(size => (
                          <SelectItem key={size} value={size}>
                            {t({
                              id: NEST_SIZE_MESSAGES[size].id,
                              message: NEST_SIZE_MESSAGES[size].message,
                            })}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </CardContent>
          </Card>
          <div className="flex justify-end">
            <Button type="submit">
              <Trans>Submit</Trans>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
