import {NestCreateDto} from './dtos/NestCreateDto'
import {NestDetailDto} from './dtos/NestDetailDto'
import {NestItemDto} from './dtos/NestItemDto'
import {NestUpdateDto} from './dtos/NestUpdateDto'
import {api} from '../api/api'
import {ApiPaginationOptions} from '../api/dtos/ApiPaginationOptionsDto'
import {ApiPaginationResponse} from '../api/dtos/ApiPaginationResponseDto'
import {ApiResponseDto} from '../api/dtos/ApiResponseDto'

export function detail(id: string) {
  return api.get(`api/nests/${id}`).json<ApiResponseDto<NestDetailDto>>()
}

export function all({page = 0}: ApiPaginationOptions) {
  return api
    .get('api/nests', {
      searchParams: {page},
    })
    .json<ApiPaginationResponse<NestItemDto[]>>()
}

export function create(json: NestCreateDto) {
  return api.post('api/nests', {json}).json<ApiResponseDto<NestDetailDto>>()
}

export function update(id: string, json: NestUpdateDto) {
  return api
    .put(`api/nests/${id}`, {json})
    .json<ApiResponseDto<NestDetailDto>>()
}

export function favorite(id: string) {
  return api
    .post(`api/nests/${id}/favorite`)
    .json<ApiResponseDto<NestDetailDto>>()
}

export const nestService = {
  detail,
  all,
  update,
  create,
  favorite,
}
