import {queryOptions} from '@tanstack/react-query'

import {HeightRequestParamsDto} from './dtos/HeightRequestParamsDto'
import {geoAdminService} from './geoAdminService'

export const geoAdminKeys = {
  all: () => ['geoAdmin'] as const,

  height: (params: HeightRequestParamsDto) =>
    [
      ...geoAdminKeys.all(),
      'height',
      params.easting,
      params.northing,
      params.sr,
    ] as const,
}

export const heightQueryOptions = (params: HeightRequestParamsDto) =>
  queryOptions({
    queryKey: geoAdminKeys.height(params),
    queryFn: () => geoAdminService.getHeight(params),
  })
