import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister'
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client'
import {FC, PropsWithChildren} from 'react'

import {queryClient} from '@/lib/api/queryClient'

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

export const QueryProvider: FC<PropsWithChildren> = ({children}) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{persister}}
      onSuccess={() => {
        // resume mutations after initial restore from localStorage was successful
        queryClient.resumePausedMutations().then(() => {
          queryClient.invalidateQueries()
        })
      }}
    >
      {children}
    </PersistQueryClientProvider>
  )
}
