import {cva} from 'class-variance-authority'
import {Star} from 'lucide-react'
import {FC, HTMLAttributes} from 'react'

type Props = {
  selected: boolean
  onClick: () => void
} & HTMLAttributes<HTMLButtonElement>

const favorite = cva(['transition-all'], {
  variants: {
    state: {
      selected: 'text-yellow-500 scale-110 fill-yellow-500',
      unselected: 'text-gray-400',
    },
  },
})

export const Favorite: FC<Props> = ({onClick, selected, ...rest}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onClick()
  }

  const className = favorite({state: selected ? 'selected' : 'unselected'})

  return (
    <button data-testid="Favorite" {...rest} onClick={handleClick}>
      <Star className={className} size={24} />
    </button>
  )
}
