/* eslint-disable no-case-declarations */
import {plural, t} from '@lingui/macro'
import {z} from 'zod'

const tooSmallMessages = (
  issue: z.ZodTooSmallIssue,
  ctx: z.ErrorMapCtx
): string => {
  const min = (issue.minimum ?? 0) as number

  switch (issue.type) {
    case 'string':
      return plural(min, {
        one: 'Field is too short',
        other: `Field must contain at least # characters`,
      })
    case 'number':
      return plural(min, {
        one: 'Field is too small',
        other: `Field must contain at least #`,
      })
    default:
      return ctx.defaultError
  }
}

const tooBigMessages = (
  issue: z.ZodTooBigIssue,
  ctx: z.ErrorMapCtx
): string => {
  const max = (issue.maximum ?? 0) as number

  switch (issue.type) {
    case 'string':
      return plural(max, {
        one: 'Field is too long',
        other: `Field must contain at most # characters`,
      })
    case 'number':
      return plural(max, {
        one: 'Field is too large',
        other: `Field must contain at most #`,
      })
    default:
      return ctx.defaultError
  }
}

const invalidString = (): string => {
  return t`Invalid email`
}

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      const {expected, received} = issue
      return {
        message: t`Invalid type, expected ${expected}, received ${received}`,
      }
    case z.ZodIssueCode.too_small:
      return {message: tooSmallMessages(issue, ctx)}

    case z.ZodIssueCode.too_big:
      return {message: tooBigMessages(issue, ctx)}
    case z.ZodIssueCode.invalid_string:
      return {message: invalidString()}
  }

  return {message: ctx.defaultError}
}

z.setErrorMap(customErrorMap)
