import {useEffect, useState} from 'react'

import {__BRANCH_DEPLOYMENT__, __DEV__} from '@/lib/utils'

const ClippyWrapper = () => {
  const [Component, setComponent] = useState<React.ReactElement | null>(null)

  useEffect(() => {
    if (!(__DEV__ || __BRANCH_DEPLOYMENT__)) {
      return
    }
    import('./Clippy').then(({Clippy}) => {
      setComponent(<Clippy />)
    })
  }, [])

  return Component
}

export const Clippy = ClippyWrapper
