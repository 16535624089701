import {FC} from 'react'

import {NestItemDto} from '@/lib/nest/dtos/NestItemDto'

import {NestItem} from './NestItem'

type FromPaths = '/nests/overview/all' | '/nests/overview/favorites'
type Props = {
  nests: NestItemDto[]
  from: FromPaths
}

export const NestList: FC<Props> = ({nests, from}) => {
  return (
    <div className="flex flex-col gap-2" data-testid="NestList">
      {nests.map(nest => (
        <NestItem from={from} key={nest.id} nest={nest} />
      ))}
    </div>
  )
}
