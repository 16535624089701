import {SyncDatabaseChangeSet} from '@nozbe/watermelondb/sync'

import {api} from '@/lib/api/api'
import {ApiResponseDto} from '@/lib/api/dtos/ApiResponseDto'

import {PullOptionsDto} from './dtos/PullOptionsDto'
import {SyncSizeDto} from './dtos/SyncSizeDto'

export function size() {
  return api.get(`api/sync/size`).json<ApiResponseDto<SyncSizeDto>>()
}

export function pull(options: PullOptionsDto) {
  return api
    .get(`api/sync/pull`, {
      searchParams: removeEmpty(options),
    })
    .json<ApiResponseDto<SyncDatabaseChangeSet>>()
}

export function push(data: SyncDatabaseChangeSet) {
  return api.post(`api/sync/push`, {json: data}).json<ApiResponseDto<void>>()
}

function removeEmpty(obj: Record<string, string | number | boolean>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export const syncService = {
  size,
  pull,
  push,
}
