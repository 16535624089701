// From https://github.com/antistatique/swisstopo/blob/dev/src/SwisstopoConverter.php

const fromMN95ToWGS = (east: number, north: number) => {
  return {
    latitude: fromMN95ToWGSLatitude(east, north),
    longitude: fromMN95ToWGSLongitude(east, north),
  }
}

/**
 * Convert the given WGS coordinate points into Swiss (MN95) notation.
 *
 * @param lat The WGS latitude coordinate point in degrees
 * @param long The WGS longitude coordinate point in degrees
 * @return The array containing Swiss (MN95) East & North coordinates
 */
const fromWGSToMN95 = (lat: number, long: number) => {
  return {
    east: fromWGSToMN95East(lat, long),
    north: fromWGSToMN95North(lat, long),
  }
}

const fromMN03ToWGS = (y: number, x: number) => {
  return {
    latitude: fromMN03ToWGSLatitude(y, x),
    longitude: fromMN03ToWGSLongitude(y, x),
  }
}

const fromWGSToMN03 = (lat: number, long: number) => {
  return {
    x: fromWGSToMN03x(lat, long),
    y: fromWGSToMN03y(lat, long),
  }
}

const fromWGSToMN03y = (lat: number, long: number) => {
  lat = degToSex(lat)
  long = degToSex(long)

  lat = degToSec(lat)
  long = degToSec(long)

  const lat_aux = (lat - 169028.66) / 10000
  const long_aux = (long - 26782.5) / 10000

  return (
    600072.37 +
    211455.93 * long_aux -
    10938.51 * long_aux * lat_aux -
    0.36 * long_aux * Math.pow(lat_aux, 2) -
    44.54 * Math.pow(long_aux, 3)
  )
}

const fromWGSToMN03x = (lat: number, long: number) => {
  lat = degToSex(lat)
  long = degToSex(long)

  lat = degToSec(lat)
  long = degToSec(long)

  const lat_aux = (lat - 169028.66) / 10000
  const long_aux = (long - 26782.5) / 10000

  return (
    200147.07 +
    308807.95 * lat_aux +
    3745.25 * Math.pow(long_aux, 2) +
    76.63 * Math.pow(lat_aux, 2) -
    194.56 * Math.pow(long_aux, 2) * lat_aux +
    119.79 * Math.pow(lat_aux, 3)
  )
}

const fromWGSToMN95North = (lat: number, long: number) => {
  // Converts Decimal Degrees to Sexagesimal Degree.
  lat = degToSex(lat)
  long = degToSex(long)

  // Convert Decimal Degrees to Seconds of Arc.
  const phi = degToSec(lat)
  const lambda = degToSec(long)

  // Calculate the auxiliary values (differences of latitude and longitude
  // relative to Bern in the unit [10000"]).
  const phi_aux = (phi - 169028.66) / 10000
  const lambda_aux = (lambda - 26782.5) / 10000

  // Process Swiss (MN95) North calculation.
  return (
    1200147.07 +
    308807.95 * phi_aux +
    3745.25 * Math.pow(lambda_aux, 2) +
    76.63 * Math.pow(phi_aux, 2) -
    194.56 * Math.pow(lambda_aux, 2) * phi_aux +
    119.79 * Math.pow(phi_aux, 3)
  )
}

const fromWGSToMN95East = (lat: number, long: number) => {
  // Converts Decimal Degrees to Sexagesimal Degree.
  lat = degToSex(lat)
  long = degToSex(long)

  // Convert Decimal Degrees to Seconds of Arc.
  const phi = degToSec(lat)
  const lambda = degToSec(long)

  // Calculate the auxiliary values (differences of latitude and longitude
  // relative to Bern in the unit [10000"]).
  const phi_aux = (phi - 169028.66) / 10000
  const lambda_aux = (lambda - 26782.5) / 10000

  // Process Swiss (MN95) East calculation.
  return (
    2600072.37 +
    211455.93 * lambda_aux -
    10938.51 * lambda_aux * phi_aux -
    0.36 * lambda_aux * Math.pow(phi_aux, 2) -
    44.54 * Math.pow(lambda_aux, 3)
  )
}

const fromMN95ToWGSLatitude = (east: number, north: number) => {
  const y_aux = (east - 2600000) / 1000000
  const x_aux = (north - 1200000) / 1000000

  // Process latitude calculation.
  let lat =
    16.9023892 +
    3.238272 * x_aux -
    0.270978 * Math.pow(y_aux, 2) -
    0.002528 * Math.pow(x_aux, 2) -
    0.0447 * Math.pow(y_aux, 2) * x_aux -
    0.014 * Math.pow(x_aux, 3)

  // Unit 10000" to 1" and converts seconds to degrees notation.
  lat = (lat * 100) / 36

  return lat
}

const fromMN95ToWGSLongitude = (east: number, north: number) => {
  // Convert the projection coordinates E (easting) and N (northing) in MN95
  // into the civilian system (Bern = 0 / 0) and express in the unit 1000 km.
  const y_aux = (east - 2600000) / 1000000
  const x_aux = (north - 1200000) / 1000000

  // Process longitude calculation.
  let long =
    2.6779094 +
    4.728982 * y_aux +
    0.791484 * y_aux * x_aux +
    0.1306 * y_aux * Math.pow(x_aux, 2) -
    0.0436 * Math.pow(y_aux, 3)

  // Unit 10000" to 1" and converts seconds to degrees notation.
  long = (long * 100) / 36

  return long
}

const fromMN03ToWGSLatitude = (y: number, x: number) => {
  // Convert the projection coordinates y and x in MN03 into the civilian
  // system (Bern = 0 / 0) and express in the unit [1000 km].
  const y_aux = (y - 600000) / 1000000
  const x_aux = (x - 200000) / 1000000

  // Process latitude calculation.
  let lat =
    16.9023892 +
    3.238272 * x_aux -
    0.270978 * Math.pow(y_aux, 2) -
    0.002528 * Math.pow(x_aux, 2) -
    0.0447 * Math.pow(y_aux, 2) * x_aux -
    0.014 * Math.pow(x_aux, 3)

  // Unit 10000" to 1" and converts seconds to degrees notation.
  lat = (lat * 100) / 36

  return lat
}

const fromMN03ToWGSLongitude = (y: number, x: number) => {
  // Convert the projection coordinates y and x in MN03 into the civilian
  // system (Bern = 0 / 0) and express in the unit [1000 km].
  const y_aux = (y - 600000) / 1000000
  const x_aux = (x - 200000) / 1000000

  // Process longitude calculation.
  let long =
    2.6779094 +
    4.728982 * y_aux +
    0.791484 * y_aux * x_aux +
    0.1306 * y_aux * Math.pow(x_aux, 2) -
    0.0436 * Math.pow(y_aux, 3)

  // Unit 10000" to 1" and converts seconds to degrees notation.
  long = (long * 100) / 36

  return long
}

const degToSex = (angle: number) => {
  // Extract D°M'S".
  const deg = Math.floor(angle)
  const min = Math.floor((angle - deg) * 60)
  const sec = ((angle - deg) * 60 - min) * 60

  // Result in degrees sexagesimal (dd.mmss)
  return deg + min / 100 + sec / 10000
}

function degToSec(angle: number) {
  // Extract D°M'S"
  const deg = Math.floor(angle)
  const min = Math.floor((angle - deg) * 100)
  const sec = ((angle - deg) * 100 - min) * 100

  // Result in seconds (dd.mmss)
  return sec + min * 60 + deg * 3600
}

export const swisstopoService = {
  fromMN95ToWGS,
  fromWGSToMN95,
  fromMN03ToWGS,
  fromWGSToMN03,
}
