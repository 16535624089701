import {Trans, t} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {ChevronLeft, PlusIcon} from 'lucide-react'
import {FC} from 'react'

import {ImageCarousel} from '@/components/Nest/NestDetail/ImageCarousel.tsx'
import {JournalTable} from '@/components/Nest/NestDetail/Journal/JournalTable.tsx'
import {Button} from '@/components/ui/button'
import {Card, CardContent} from '@/components/ui/card'
import {NestDetailDto as Data} from '@/lib/nest/dtos/NestDetailDto'
import {NEST_SIZE_MESSAGES} from '@/lib/nest/dtos/NestSizeDto'

import {NestFavorite} from '../NestFavorite'

type FromPaths = '/nests/overview/all' | '/nests/overview/favorites'
type Props = {
  nest: Data
  from: FromPaths
}

export const NestDetail: FC<Props> = ({nest, from}) => {
  return (
    <div className="flex flex-col gap-4" data-testid="NestDetail">
      <div className="flex items-center gap-4">
        <Link data-testid="NestDetail.back" to={from} search={{page: 0}}>
          <Button variant="outline" size="icon" className="h-7 w-7">
            <ChevronLeft className="w-4 h-4" />
            <span className="sr-only">
              <Trans>Back</Trans>
            </span>
          </Button>
        </Link>

        <h1
          data-testid="NestDetail.title"
          className="flex-1 text-xl font-semibold tracking-tight shrink-0 whitespace-nowrap sm:grow-0"
        >
          {nest.nestCode} - {nest.species}
        </h1>

        <span className="flex-1"></span>

        <NestFavorite nest={nest} />
      </div>
      <Card
        style={{viewTransitionName: `nestCard-${nest.id}`}}
        className="pt-6"
      >
        <CardContent className="relative flex flex-col w-full gap-4">
          <ImageCarousel />
          <div>
            <div>
              <strong>
                <Trans>Latitude</Trans>:{' '}
              </strong>
              <span data-testid="NestDetail.latitude">{nest.latitude}</span>
            </div>
            <div>
              <strong>
                <Trans>Longitude</Trans>:{' '}
              </strong>
              <span data-testid="NestDetail.longitude">{nest.longitude}</span>
            </div>
            <div>
              <strong>
                <Trans>Place</Trans>:{' '}
              </strong>
              <span data-testid="NestDetail.place">{nest.place}</span>
            </div>
            <div>
              <strong>
                <Trans>Size</Trans>:{' '}
              </strong>
              <span data-testid="NestDetail.size">
                {t({
                  id: NEST_SIZE_MESSAGES[nest.size].id,
                  message: NEST_SIZE_MESSAGES[nest.size].message,
                })}
              </span>
            </div>
          </div>
          <div>
            <strong>
              <Trans>Journal Entries</Trans>:{' '}
            </strong>
            <div className="flex flex-col gap-4">
              <JournalTable />
              <Button className="flex w-32 gap-2 text-background">
                <PlusIcon className="w-4 h-4" />
                <Trans>Add</Trans>
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
