import {FC} from 'react'

import {Skeleton} from '@/components/ui/skeleton'

export const NestListLoading: FC = () => {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="w-full h-96 rounded-xl" />

      <div className="space-y-2">
        <Skeleton className="w-full h-56 rounded-3xl" />
        <Skeleton className="w-full h-56 rounded-3xl" />
        <Skeleton className="w-full h-56 rounded-3xl" />
        <Skeleton className="w-full h-56 rounded-3xl" />
        <Skeleton className="w-full h-56 rounded-3xl" />
        <Skeleton className="w-full h-56 rounded-3xl" />
      </div>
    </div>
  )
}
