import {jwtDecode} from 'jwt-decode'
import {create} from 'zustand'
import {PersistOptions, persist} from 'zustand/middleware'

import {CustomJwtPayload} from './entity/CustomJwtPayload'

type AuthStore = {
  role?: string
  token?: string
  isAuthenticated: () => boolean
  login: (token: string) => void
  logout: () => void
}

const PERSIST_OPTIONS: PersistOptions<AuthStore> = {
  name: 'auth',
  version: 1,
  getStorage: () => localStorage,
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      role: undefined,
      token: undefined,
      isAuthenticated: () => {
        return !!get().token
      },
      login: (token: string) => {
        const decoded = jwtDecode<CustomJwtPayload>(token)
        const role = decoded.role ? decoded.role : ''
        set(() => ({
          token,
          role,
        }))
      },
      logout: () => {
        set(() => ({
          token: undefined,
          role: undefined,
        }))
      },
    }),
    PERSIST_OPTIONS
  )
)
