import nestImage1 from '@/assets/nest1.jpg'
import nestImage2 from '@/assets/nest2.jpg'
import nestImage3 from '@/assets/nest3.jpg'
import nestImage4 from '@/assets/nest4.jpg'
import nestImage5 from '@/assets/nest5.jpg'
import {Card, CardContent} from '@/components/ui/card.tsx'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel.tsx'

const images = [nestImage1, nestImage2, nestImage3, nestImage4, nestImage5]

export const ImageCarousel = () => {
  return (
    <Carousel className="w-full h-full">
      <CarouselContent>
        {Array.from({length: 5}).map((_, index) => (
          <CarouselItem key={index}>
            <div className="p-1">
              <Card>
                <CardContent className="flex items-center justify-center p-0 aspect-video ">
                  <img
                    alt={images[index].toString()}
                    src={images[index]}
                    className="object-cover w-full h-full rounded-md "
                  />
                  <span className="absolute text-4xl font-semibold text-white opacity-50">
                    {index + 1}
                  </span>
                </CardContent>
              </Card>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-3 bg-transparent border-none text-white" />
      <CarouselNext className="right-3 bg-transparent border-none text-white" />
    </Carousel>
  )
}
