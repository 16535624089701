import {FC} from 'react'

import {Skeleton} from '@/components/ui/skeleton'

export const NestDetailLoading: FC = () => {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-8 w-[250px] rounded-xl" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
        <Skeleton className="h-4 w-[230px]" />
        <Skeleton className="h-4 w-[220px]" />
        <Skeleton className="h-4 w-[180px]" />
      </div>
    </div>
  )
}
