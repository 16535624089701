import {Model, tableSchema} from '@nozbe/watermelondb'

type TableSchema = Parameters<typeof tableSchema>[0]
type Column = TableSchema['columns'][number]

type WatermelonModelFields = keyof Model
type Class<TModel extends typeof Model> = TModel extends {
  new (...args: any[]): infer U
}
  ? U
  : never

/**
 * Removes all fields that are inherited from the Watermelon Model class
 */
export type ModelFieldNames<TModel extends Model> = Exclude<
  keyof TModel,
  WatermelonModelFields
>

export type ModelSchema<TModel extends typeof Model> = Omit<
  TableSchema,
  'columns'
> & {
  name: TModel['table']
  columns: Record<ModelFieldNames<Class<TModel>>, Omit<Column, 'name'>>
}

export const tableSchemaTypesecure = <
  TModel extends typeof Model = typeof Model,
>(
  arg0: ModelSchema<TModel>
) => {
  const columns = Object.entries(arg0.columns).map(([key, value]) => ({
    ...(value as Column),
    name: key,
  }))

  return tableSchema({
    name: arg0.name,
    columns,
  })
}
