import {useQuery} from '@tanstack/react-query'
import {createFileRoute} from '@tanstack/react-router'
import {z} from 'zod'

import {ApiPagination} from '@/components/ApiPagination'
import {NestList} from '@/components/Nest/NestList'
import {NestListError} from '@/components/Nest/NestList/error'
import {NestListLoading} from '@/components/Nest/NestList/loading'
import {NestMap} from '@/components/Nest/NestMap'
import {nestsQueryOptions} from '@/lib/nest/query'

const nestListSearchParamsSchema = z.object({
  page: z.number().int().positive().catch(0),
})

export const Route = createFileRoute('/nests/overview/all/')({
  loaderDeps: ({search: {page}}) => ({page}),
  loader: ({context: {queryClient}, deps: {page}}) => {
    return queryClient.ensureQueryData(nestsQueryOptions({page}))
  },
  pendingComponent: NestListLoading,
  component: NestComponent,
  errorComponent: NestListError,
  pendingMs: 0,
  validateSearch: nestListSearchParamsSchema,
})

function NestComponent() {
  const search = Route.useSearch()
  const nestsQuery = useQuery(nestsQueryOptions({page: search.page}))

  if (!nestsQuery.data) {
    return null
  }

  const {data: nests, page, next, previous, totalPages} = nestsQuery.data

  return (
    <>
      <NestMap nests={nests} />
      <NestList from="/nests/overview/all" nests={nests} />
      <ApiPagination
        next={next}
        page={page}
        previous={previous}
        totalPages={totalPages}
      />
    </>
  )
}
