import {zodResolver} from '@hookform/resolvers/zod'
import {t} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {NestForm} from '@/components/Nest/NestForm'
import {queryClient} from '@/lib/api/queryClient'
import {NestCreateDto} from '@/lib/nest/dtos/NestCreateDto'
import {nestService} from '@/lib/nest/nestService'
import {nestKeys} from '@/lib/nest/query'

export const Route = createFileRoute('/nests/overview/all/new')({
  component: NestNewComponent,
})

function NestNewComponent() {
  const form = useForm<NestCreateDto>({
    resolver: zodResolver(NestCreateDto),
  })

  const navigation = useNavigate({from: '/nests/overview/all'})

  const {mutate} = useMutation({
    mutationFn: (newNest: NestCreateDto) => nestService.create(newNest),
    onSettled: () => queryClient.invalidateQueries({queryKey: nestKeys.all()}),
    mutationKey: nestKeys.create(),
    onSuccess: data => {
      toast.success(t`New nest created`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
      const newId = data.data.id
      navigation({
        to: '../$nestId',
        from: '/nests/overview/all/new',
        params: {nestId: newId},
      })
    },
  })

  const onSubmit = (nest: NestCreateDto) => {
    mutate(nest)
  }

  return <NestForm form={form} onSubmit={onSubmit} />
}
