import {Trans} from '@lingui/macro'
import {useDatabase} from '@nozbe/watermelondb/react'
import {createFileRoute} from '@tanstack/react-router'
import {useObservable, useObservableState} from 'observable-hooks'

import {NestList} from '@/components/Nest/NestList'
import {NestListError} from '@/components/Nest/NestList/error'
import {NestListLoading} from '@/components/Nest/NestList/loading'
import {NestMap} from '@/components/Nest/NestMap'
import {Button} from '@/components/ui/button'
import {sync} from '@/lib/db/sync'
import {NestItemDto} from '@/lib/nest/dtos/NestItemDto'
import {NestModel} from '@/lib/nest/models/NestModel'

export const Route = createFileRoute('/nests/overview/favorites/')({
  pendingComponent: NestListLoading,
  component: NestComponent,
  errorComponent: NestListError,
  pendingMs: 0,
})

function NestComponent() {
  const db = useDatabase()
  const nests$ = useObservable(() =>
    db.collections.get<NestModel>('nests').query().observe()
  )
  const nestState = useObservableState(nests$, null)

  const nests = nestState?.map(nest => NestItemDto.parse(nest)) || []

  return (
    <>
      <NestMap nests={nests} />
      <Button onClick={() => sync()}>
        <Trans>Sync</Trans>
      </Button>
      {nests.length > 0 ? (
        <NestList nests={nests} from="/nests/overview/favorites" />
      ) : (
        <p>
          <Trans>No nests :(</Trans>
        </p>
      )}
    </>
  )
}
