import {Link} from '@tanstack/react-router'
import {icons} from 'lucide-react'
import {FC, PropsWithChildren} from 'react'

type Props = {
  icon: keyof typeof icons
} & React.ComponentProps<typeof Link>

export const NavItem: FC<PropsWithChildren<Props>> = ({
  children,
  icon,
  ...props
}) => {
  const Icon = icons[icon]
  return (
    <Link
      {...props}
      className="flex flex-col items-center w-16 gap-1 text-sm transition-all md:px-2 md:text-base"
      activeProps={{
        className: 'font-bold text-primary',
      }}
      activeOptions={{exact: true}}
    >
      <Icon size={20} className="md:hidden" />
      {children}
    </Link>
  )
}
