import {Trans} from '@lingui/macro'
import {FC} from 'react'

import {AuthenticationButton} from '@/components/Navigation/AuthenticationButton.tsx'
import {NetworkStatus} from '@/components/Navigation/NetworkStatus'
import {cn} from '@/lib/utils'

import {LanguageSwitcher} from './LanguageSwitcher'
import {NavItem} from './NavItem'

export const Navigation: FC = () => {
  return (
    <>
      <div className="md:h-16"></div>
      <nav
        data-testid="navigation"
        className={cn(
          'fixed z-30 bottom-0 md:top-0 md:bottom-[initial] -translate-x-1/2 left-1/2',
          'w-full max-w-screen-md',
          'vt-name-[navigation]'
        )}
      >
        <div
          className={cn(
            'flex items-center',
            'p-2 pl-4 pr-4 m-4',
            'text-foreground text-lg bg-white',
            'border border-gray-100 rounded-sm'
          )}
        >
          <NavItem
            to="/nests/overview/all"
            activeOptions={{exact: false, includeSearch: false}}
            icon="Bug"
          >
            <Trans>Nests</Trans>
          </NavItem>
          <span className="flex-1" />
          <LanguageSwitcher />
          <NetworkStatus />
          <AuthenticationButton />
        </div>
      </nav>
    </>
  )
}
