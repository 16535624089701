import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import {DatabaseProvider} from '@nozbe/watermelondb/react'
import {QueryClient} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
} from '@tanstack/react-router'
import {TanStackRouterDevtools} from '@tanstack/router-devtools'

import {Clippy} from '@/components/Clippy'
import {Navigation} from '@/components/Navigation'
import {QueryProvider} from '@/components/QueryProvider'
import {Toaster} from '@/components/ui/sonner'
import {database} from '@/lib/db'
import {__BRANCH_DEPLOYMENT__, __DEV__} from '@/lib/utils'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
  database: typeof database
}>()({
  component: RootComponent,
})

export function RootComponent() {
  return (
    <QueryProvider>
      <DatabaseProvider database={database}>
        <ScrollRestoration />
        <I18nProvider i18n={i18n}>
          <Navigation />
          <Toaster
            position="top-right"
            visibleToasts={9}
            toastOptions={{
              duration: 5000,
              className: 'toast-container',
            }}
          />
          <Outlet />
          {(__DEV__ || __BRANCH_DEPLOYMENT__) && (
            <>
              <TanStackRouterDevtools position="bottom-right" />
              <ReactQueryDevtools initialIsOpen={false} />
              <Clippy />
            </>
          )}
        </I18nProvider>
      </DatabaseProvider>
    </QueryProvider>
  )
}
