import {zodResolver} from '@hookform/resolvers/zod'
import {t} from '@lingui/macro'
import {useMutation} from '@tanstack/react-query'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {LoginForm} from '@/components/Auth/LoginForm'
import {MainLayout} from '@/components/Layout/MainLayout'
import {authService} from '@/lib/auth/authService'
import {useAuthStore} from '@/lib/auth/authStore'
import {LoginDto} from '@/lib/auth/dtos/LoginDto'

export const Route = createFileRoute('/auth/')({
  component: LoginComponent,
})

function LoginComponent() {
  const form = useForm<LoginDto>({
    resolver: zodResolver(LoginDto),
  })

  const navigation = useNavigate({from: '/auth'})
  const [login, logout] = useAuthStore(state => [state.login, state.logout])

  const {mutate} = useMutation({
    mutationFn: (loginCredentials: LoginDto) =>
      authService.login(loginCredentials),
    onSuccess: data => {
      toast.success(t`Login successful`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
      login(data.data.token)
      navigation({to: '/nests/overview/all', search: {page: 0}}).then()
    },
    onError: () => {
      toast.error(t`Login failed`, {
        cancel: {
          label: t`Dismiss`,
          onClick: () => toast.dismiss(),
        },
      })
      logout()
    },
  })

  const onSubmit = (loginData: LoginDto) => {
    mutate(loginData)
  }

  return (
    <MainLayout>
      <div className="flex items-center justify-center flex-grow w-full h-full">
        <div className="w-full max-w-96">
          <LoginForm form={form} onSubmit={onSubmit} />
        </div>
      </div>
    </MainLayout>
  )
}
