import {Trans, t} from '@lingui/macro'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from '@radix-ui/react-icons'
import {Link} from '@tanstack/react-router'
import * as React from 'react'

import {ButtonProps, buttonVariants} from '@/components/ui/button'
import {cn} from '@/lib/utils'

const Pagination = ({className, ...props}: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)

// eslint-disable-next-line lingui/no-unlocalized-strings
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({className, ...props}, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
))

// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({className, ...props}, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Omit<React.ComponentProps<typeof Link>, 'size'> &
  Pick<ButtonProps, 'size'>

const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) => (
  <Link
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      className
    )}
    {...props}
  />
)

// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label={t`Go to previous page`}
    size="default"
    className={cn('gap-1 pl-2.5', className)}
    {...props}
  >
    <ChevronLeftIcon className="w-4 h-4" />
    <span>
      <Trans>Previous</Trans>
    </span>
  </PaginationLink>
)

// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label={t`Go to next page`}
    size="default"
    className={cn('gap-1 pr-2.5', className)}
    {...props}
  >
    <span>
      <Trans>Next</Trans>
    </span>
    <ChevronRightIcon className="w-4 h-4" />
  </PaginationLink>
)

// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <DotsHorizontalIcon className="w-4 h-4" />
    <span className="sr-only">
      <Trans>More pages</Trans>
    </span>
  </span>
)

// eslint-disable-next-line lingui/no-unlocalized-strings
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationLink,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
}
