import {Trans} from '@lingui/macro'
import {useQuery} from '@tanstack/react-query'
import {Compass, LoaderCircle} from 'lucide-react'
import {FC, useMemo} from 'react'

import {Alert, AlertTitle, AlertDescription} from '@/components/ui/alert'
import {heightQueryOptions} from '@/lib/geoadmin/query'
import useDebounce from '@/lib/hooks/useDebounce'
import {swisstopoService} from '@/lib/swisstopo/swisstopoService'

type Props = {
  latitude: number
  longitude: number
}

export const SwissCoordinatesAlert: FC<Props> = ({latitude, longitude}) => {
  const lv03 = useMemo(
    () => swisstopoService.fromWGSToMN03(latitude, longitude),
    [latitude, longitude]
  )

  const lv95 = useMemo(
    () => swisstopoService.fromWGSToMN95(latitude, longitude),
    [latitude, longitude]
  )

  const lv03Debounced = useDebounce(lv03, 500)

  const heightQuery = useQuery({
    ...heightQueryOptions({
      easting: lv03Debounced.y,
      northing: lv03Debounced.x,
      sr: 'LV03',
    }),
  })

  return (
    <Alert variant="default">
      <Compass className="w-4 h-4 -mt-1" />
      <div className="flex"></div>
      <AlertTitle>
        <Trans>Swiss Coordinates (LV03)</Trans>
      </AlertTitle>
      <AlertDescription>
        <dl>
          <div className="flex">
            <dt className="w-16">
              <Trans>East:</Trans>
            </dt>
            <dd>{lv03.x}</dd>
          </div>
          <div className="flex">
            <dt className="w-16">
              <Trans>North:</Trans>
            </dt>
            <dd>{lv03.y}</dd>
          </div>
        </dl>
      </AlertDescription>
      <AlertTitle className="mt-2">
        <Trans>Swiss Coordinates (LV95)</Trans>
      </AlertTitle>
      <AlertDescription>
        <dl>
          <div className="flex">
            <dt className="w-16">
              <Trans>East:</Trans>
            </dt>
            <dd>{lv95.east}</dd>
          </div>
          <div className="flex">
            <dt className="w-16">
              <Trans>North:</Trans>
            </dt>
            <dd>{lv95.north}</dd>
          </div>
        </dl>
      </AlertDescription>
      <AlertTitle className="mt-2">
        <Trans>Metadata</Trans>
      </AlertTitle>
      <AlertDescription>
        <dl>
          <div className="flex">
            <dt className="w-16">
              <Trans>Altitude:</Trans>
            </dt>
            <dd>
              {heightQuery.isLoading ? (
                <LoaderCircle className="w-4 h-4 animate-spin" />
              ) : (
                heightQuery.data?.height
              )}
            </dd>
          </div>
        </dl>
      </AlertDescription>
    </Alert>
  )
}
