import {i18n} from '@lingui/core'
import {create} from 'zustand'
import {PersistOptions, createJSONStorage, persist} from 'zustand/middleware'
import './validationMessages'

export const locales = {
  de: 'DE',
  en: 'EN',
} as const

//////////////////////////////////////////////////////////////////////

export type Locale = keyof typeof locales
export const defaultLocale: Locale = 'en'

async function dynamicActivate(locale: Locale) {
  const {messages} = await import(`../../locales/${locale}.po`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

type LanguageStore = {
  language: Locale
  setLanguage: (language: Locale) => Promise<void>
  isLoaded: boolean
}

const PERSIST_OPTIONS: PersistOptions<LanguageStore> = {
  name: 'i18n',
  version: 1,
  storage: createJSONStorage(() => localStorage),
  partialize: (state: LanguageStore) =>
    ({language: state.language}) as LanguageStore,
}

export const useLanguageStore = create<LanguageStore>()(
  persist(
    set => ({
      language: defaultLocale,
      setLanguage: async language => {
        set({isLoaded: false})
        await dynamicActivate(language)
        set({language, isLoaded: true})
      },
      isLoaded: false,
    }),
    PERSIST_OPTIONS
  )
)
