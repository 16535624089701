import {createFileRoute} from '@tanstack/react-router'

import {NestDetail as NestDetailCmp} from '@/components/Nest/NestDetail'
import {NestDetailError} from '@/components/Nest/NestDetail/error'
import {NestDetailLoading} from '@/components/Nest/NestDetail/loading'
import {NestDetailDto} from '@/lib/nest/dtos/NestDetailDto'
import {NestModel} from '@/lib/nest/models/NestModel'

export const Route = createFileRoute('/nests/overview/favorites/$nestId')({
  loader: async ({context: {database}, params: {nestId}}) => {
    return database.get<NestModel>('nests').find(nestId)
  },

  pendingComponent: NestDetailLoading,
  errorComponent: NestDetailError,
  component: NestDetailComponent,
  pendingMs: 0,
})

function NestDetailComponent() {
  const data = Route.useLoaderData()
  const nest = NestDetailDto.parse(data)

  return <NestDetailCmp nest={nest} from="/nests/overview/favorites" />
}
