import {queryOptions} from '@tanstack/react-query'

import {nestService} from '@/lib/nest/nestService'

import {ApiPaginationOptions} from '../api/dtos/ApiPaginationOptionsDto'

export const nestKeys = {
  all: () => ['nest'] as const,

  list: (page: number) => [...nestKeys.all(), 'list', page] as const,

  details: () => [...nestKeys.all(), 'detail'] as const,
  detail: (id: string) => [...nestKeys.details(), id] as const,

  create: () => [...nestKeys.all(), 'create'] as const,

  favorites: () => [...nestKeys.all(), 'favorite'] as const,
  favorite: (id: string) => [...nestKeys.favorites(), id] as const,
}

export const nestsQueryOptions = ({page = 0}: ApiPaginationOptions = {}) =>
  queryOptions({
    queryKey: nestKeys.list(page),
    queryFn: () => nestService.all({page}),
  })

export const nestQueryOptions = (id: string) =>
  queryOptions({
    queryKey: nestKeys.detail(id),
    queryFn: () => nestService.detail(id),
  })
