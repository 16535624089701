import {Trans} from '@lingui/macro'
import {Link} from '@tanstack/react-router'
import {FC} from 'react'

import {Tabs, TabsList, TabsTrigger} from '@/components/ui/tabs'

import {NEST_LIST_TAB, NestListTab} from './types'

type Props = {
  value: NestListTab
}

export const NestListTabs: FC<Props> = ({value}) => {
  return (
    <Tabs value={value} className="w-full vt-name-[nestListTabs]">
      <TabsList>
        <Link to="/nests/overview/all" search={{page: 0}}>
          <TabsTrigger value={NEST_LIST_TAB.ALL}>
            <Trans>All</Trans>
          </TabsTrigger>
        </Link>
        <Link to="/nests/overview/favorites" search={{page: 0}}>
          <TabsTrigger value={NEST_LIST_TAB.FAVORITES}>
            <Trans>Favorites</Trans>
          </TabsTrigger>
        </Link>
      </TabsList>
    </Tabs>
  )
}
