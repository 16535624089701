import {zodResolver} from '@hookform/resolvers/zod'
import {t} from '@lingui/macro'
import {useDatabase} from '@nozbe/watermelondb/react'
import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {useForm} from 'react-hook-form'
import {toast} from 'sonner'

import {NestForm} from '@/components/Nest/NestForm'
import {NestCreateDto} from '@/lib/nest/dtos/NestCreateDto'
import {NestModel} from '@/lib/nest/models/NestModel'

export const Route = createFileRoute('/nests/overview/favorites/new')({
  component: NestNewComponent,
})

function NestNewComponent() {
  const form = useForm<NestCreateDto>({
    resolver: zodResolver(NestCreateDto),
  })

  const db = useDatabase()

  const navigation = useNavigate({from: '/nests/overview/favorites/new'})

  const onSubmit = async (nest: NestCreateDto) => {
    const newNest = await db.write(async () => {
      const newNest = await db.get<NestModel>('nests').create(n => {
        n.nestCode = nest.nestCode!
        n.latitude = nest.latitude!
        n.longitude = nest.longitude!
        n.size = nest.size!
        n.species = nest.species!
        n.isFavorite = true
      })

      return newNest
    })

    toast.success(t`New nest created`, {
      cancel: {
        label: t`Dismiss`,
        onClick: () => toast.dismiss(),
      },
    })

    const newId = newNest.id
    navigation({
      to: '../$nestId',
      params: {nestId: newId},
    })
  }

  return <NestForm form={form} onSubmit={onSubmit} />
}
